





















































import { computed, defineComponent } from '@vue/composition-api';
import { useStakingStore } from '../../stores/Staking';
import { useAddressStore } from '../../stores/Address';
import { useRouter } from '../../router';
import Amount from '../Amount.vue';
import FiatConvertedAmount from '../FiatConvertedAmount.vue';
import TwoLeafStakingIcon from '../icons/Staking/TwoLeafStakingIcon.vue';
import ValidatorIcon from './ValidatorIcon.vue';
import ValidatorTrustScore from './tooltips/ValidatorTrustScore.vue';
import ValidatorReward from './tooltips/ValidatorReward.vue';

export default defineComponent({
    setup() {
        const { activeAddressInfo } = useAddressStore();
        const { activeStake: stake, activeValidator: validator, restakingRewards } = useStakingStore();
        const router = useRouter();

        const availableBalance = computed(() => activeAddressInfo.value?.balance || 0);
        const stakedBalance = computed(() => stake.value ? stake.value.activeBalance : 0);

        const percentage = computed(() => Math.round((
            stakedBalance.value / (availableBalance.value + stakedBalance.value + (stake.value?.inactiveBalance || 0))
        ) * 100));

        const openStakingModal = () => {
            router.push({ name: 'staking' });
        };

        return {
            stake,
            validator,
            restakingRewards,
            percentage,
            openStakingModal,
        };
    },
    components: {
        Amount,
        FiatConvertedAmount,
        TwoLeafStakingIcon,
        ValidatorIcon,
        ValidatorTrustScore,
        ValidatorReward,
    },
});
