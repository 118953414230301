var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"validator-trust-score",class:{
        'high-score': !_vm.dry && !_vm.disabled && _vm.stars >= 4.5,
        'low-score': !_vm.dry && !_vm.disabled && _vm.stars >= 2.5 && _vm.stars < 3.5,
        'very-low-score': !_vm.dry && !_vm.disabled && _vm.stars < 2.5,
        'disabled': _vm.disabled,
        dry: _vm.dry,
        borderless: _vm.borderless,
    }},[_c('StarIcon'),_vm._v(" "+_vm._s(_vm.displayValue)+" ")],1)}
var staticRenderFns = []

export { render, staticRenderFns }