











import { computed, defineComponent } from '@vue/composition-api';

export default defineComponent({
    props: {
        reward: {
            type: Number,
            required: true,
        },
        dry: {
            type: Boolean,
            default: false,
            required: false,
        },
        percentOnly: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    setup(props) {
        // Ensure we show at most 3 significant digits (except if the integer part is longer than that)
        const formatted = computed(() => {
            const percentage = (props.reward * 100).toString();
            let [integers, decimals] = percentage.split('.');
            if (integers === '0') integers = '';
            const numberOfIntegers = integers.length;
            const numberOfDecimals = 3 - numberOfIntegers;
            decimals = (decimals || '').substring(0, numberOfDecimals);
            return [
                integers || '0',
                decimals ? '.' : '',
                decimals,
            ].join('');
        });

        return {
            formatted,
        };
    },
});
