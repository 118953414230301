
















import { computed, defineComponent } from '@vue/composition-api';
import StarIcon from '../../icons/Staking/StarIcon.vue';

export default defineComponent({
    props: {
        score: {
            type: Number,
            default: null,
        },
        dry: {
            type: Boolean,
            required: false,
            default: false,
        },
        borderless: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const disabled = computed(() => props.score === null);
        const stars = computed(() => props.score !== null ? props.score * 5 : 0);
        const displayValue = computed(() => disabled.value ? '-' : stars.value.toFixed(2));

        return {
            stars,
            disabled,
            displayValue,
        };
    },
    components: {
        StarIcon,
    },
});
