import { render, staticRenderFns } from "./StakingIcon.vue?vue&type=template&id=593af575&scoped=true&"
import script from "./StakingIcon.vue?vue&type=script&lang=ts&"
export * from "./StakingIcon.vue?vue&type=script&lang=ts&"
import style0 from "./StakingIcon.vue?vue&type=style&index=0&id=593af575&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "593af575",
  null
  
)

export default component.exports