










import { defineComponent } from '@vue/composition-api';
import { Identicon } from '@nimiq/vue-components';
import { Validator } from '../../stores/Staking';

export default defineComponent({
    props: {
        validator: {
            type: Object as () => Validator,
            required: true,
        },
    },
    components: {
        Identicon,
    },
});
